<template>
  <div v-capture-scroll="onScroll">
    <div class="d-flex justify-content-between sticky-top bg-white top-section">
      <div>
        <h3 class="mb-2 payments-table-title">
          {{ $t('payment.paymentTable.paymentsForApproval') }}
        </h3>
        <div class="table-filters">
          <DropdownTableFilter
            :filter-name="$t('payment.paymentTable.filters.reconciliationStatus')"
            :filter-value="reconciliationStatusFilterText"
            trigger="click"
            :clearable="true"
            @on-choose-item="onReconciliationStatusFilterChanged"
            @on-clear-filter="onReconciliationStatusFilterChanged(null)"
          >
            <div slot="filter-value" class="reconciliation-status-filter">
              <span
                class="reconciliation-status-filter-icon"
                :class="{
                  [`bg-${RECONCILIATION_STATUS_COLOR[reconciliationStatusFilter]}`]: true,
                }"
              />
              {{ reconciliationStatusFilterText }}
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="RECONCILIATION_STATUSES.APPROVED">
                <div class="reconciliation-status-filter">
                  <span
                    class="reconciliation-status-filter-icon"
                    :class="{
                      [`bg-${RECONCILIATION_STATUS_COLOR[RECONCILIATION_STATUSES.APPROVED]}`]: true,
                    }"
                  />
                  {{ $t('reconciliation.exports.status.approved') }}
                </div>
              </el-dropdown-item>
              <el-dropdown-item :command="RECONCILIATION_STATUSES.NOT_APPROVED">
                <div class="reconciliation-status-filter">
                  <span
                    class="reconciliation-status-filter-icon"
                    :class="{
                      [`bg-${RECONCILIATION_STATUS_COLOR[RECONCILIATION_STATUSES.NOT_APPROVED]}`]: true,
                    }"
                  />
                  {{ $t('reconciliation.exports.status.notApproved') }}
                </div>
              </el-dropdown-item>
              <el-dropdown-item :command="RECONCILIATION_STATUSES.PENDING">
                <div class="reconciliation-status-filter">
                  <span
                    class="reconciliation-status-filter-icon"
                    :class="{
                      [`bg-${RECONCILIATION_STATUS_COLOR[RECONCILIATION_STATUSES.PENDING]}`]: true,
                    }"
                  />
                  {{ $t('reconciliation.exports.status.pending') }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </DropdownTableFilter>
          <DatePickerTableFilter
            :filter-name="$t('payment.paymentTable.filters.paymentDueDate')"
            :filter-value="paymentDueDateFilterText"
            value-format="yyyy-MM"
            type="month"
            size="small"
            align="center"
            :date-range="paymentDueDateFilter"
            :clearable="true"
            :picker-options="pickerOptions"
            @on-date-changed="onPaymentDueDateFilterChanged"
            @on-clear-filter="onPaymentDueDateFilterChanged(null)"
          />
          <DatePickerTableFilter
            :filter-name="$t('payment.paymentTable.headers.reconciliationPeriod')"
            :filter-value="reconciliationPeriodFilterText"
            value-format="yyyy-MM"
            type="month"
            size="small"
            align="center"
            :date-range="reconciliationPeriodFilter"
            :clearable="true"
            :picker-options="pickerOptions"
            @on-date-changed="onBillingDateFilterChanged"
            @on-clear-filter="onBillingDateFilterChanged(null)"
          />
          <DropdownTableFilter
            :filter-name="$t('payment.paymentTable.filters.paymentMethod')"
            :filter-value="reconciliationPaymentMethodFilterText"
            trigger="click"
            :clearable="true"
            @on-clear-filter="onReconciliationPaymentMethodFilterReset"
          >
            <div slot="filter-value" class="reconciliation-payment-method-filter">
              {{ reconciliationPaymentMethodFilterText }}
            </div>
            <el-dropdown-menu slot="dropdown" class="reconciliation-payment-method-dropdown">
              <el-checkbox-group v-model="paymentMethodFilterList" @change="onReconciliationPaymentMethodFilterChanged">
                <el-checkbox :label="PAYMENT_METHODS.BANK_TRANSFER">
                  <div class="reconciliation-payment-method-filter">
                    <div class="reconciliation-payment-method-filter-icon">
                      <BankTransferIcon class="method-icon" />
                    </div>
                    <div class="payment-method-title">
                      {{ $t(`payment.paymentTable.filtersPaymentMethod.${PAYMENT_METHODS.BANK_TRANSFER}`) }}
                    </div>
                  </div>
                </el-checkbox>
                <el-checkbox :label="PAYMENT_METHODS.BANK_TRANSFER_DEBIT">
                  <div class="reconciliation-payment-method-filter">
                    <div class="reconciliation-payment-method-filter-icon">
                      <BankTransferIcon class="method-icon" />
                      <RecurringIcon class="recurring-icon" />
                    </div>
                    <div class="payment-method-title">
                      {{ $t(`payment.paymentTable.filtersPaymentMethod.${PAYMENT_METHODS.BANK_TRANSFER_DEBIT}`) }}
                    </div>
                  </div>
                </el-checkbox>
                <el-checkbox :label="PAYMENT_METHODS.CREDIT_CARD">
                  <div class="reconciliation-payment-method-filter">
                    <div class="reconciliation-payment-method-filter-icon">
                      <CreditCardIcon class="method-icon" />
                    </div>
                    <div class="payment-method-title">
                      {{ $t(`payment.paymentTable.filtersPaymentMethod.${PAYMENT_METHODS.CREDIT_CARD}`) }}
                    </div>
                  </div>
                </el-checkbox>
                <el-checkbox :label="PAYMENT_METHODS.CREDIT_CARD_DEBIT">
                  <div class="reconciliation-payment-method-filter">
                    <div class="reconciliation-payment-method-filter-icon">
                      <CreditCardIcon class="method-icon" />
                      <RecurringIcon class="recurring-icon" />
                    </div>
                    <div class="payment-method-title">
                      {{ $t(`payment.paymentTable.filtersPaymentMethod.${PAYMENT_METHODS.CREDIT_CARD_DEBIT}`) }}
                    </div>
                  </div>
                </el-checkbox>
                <el-checkbox :label="PAYMENT_METHODS.CHECK">
                  <div class="reconciliation-payment-method-filter">
                    <div class="reconciliation-payment-method-filter-icon">
                      <ChequeIcon class="method-icon" />
                    </div>
                    <div class="payment-method-title">
                      {{ $t(`payment.paymentTable.filtersPaymentMethod.${PAYMENT_METHODS.CHECK}`) }}
                    </div>
                  </div>
                </el-checkbox>
              </el-checkbox-group>
            </el-dropdown-menu>
          </DropdownTableFilter>
        </div>
      </div>
      <div class="d-flex align-items-top gap-4 h-100">
        <el-dropdown v-if="selectedCount" trigger="click" @command="openPaymentOperationModal($event)">
          <Button :style="{ padding: '4px' }" :class="$direction === 'rtl' ? 'pe-4' : 'ps-4'">
            {{ $t('payment.paymentTable.operations', { count: selectedCount }) }}
            <ChevronIcon direction="down" />
          </Button>
          <el-dropdown-menu slot="dropdown">
            <div class="d-flex align-items-center">
              <el-dropdown-item
                :command="ACTIONS.MAKE_PAYMENT"
                :disabled="!hasPaymentInitiate || !currentTenantBankAccountExist"
              >
                <div
                  class="d-flex align-items-center gap-2 text-typography-primary"
                  :class="`${
                    !currentTenantBankAccountExist || !hasPaymentInitiate ? 'text-disabled' : 'text-typography-primary'
                  }`"
                >
                  <PaymentsNisIcon v-if="isNis" />
                  <PaymentsUsdIcon v-else />
                  <p>
                    {{
                      $tc('payment.paymentTable.actions.makePayment', selectedCount, {
                        count: selectedCount,
                        amount: formatToCurrency(selectedAmount),
                      })
                    }}
                  </p>
                </div>
              </el-dropdown-item>
              <el-tooltip
                :content="$t('document.documentsOverview.tenantDocumentsTable.downloadFiles.notPermitted')"
                placement="top"
              >
                <LockInvertedIcon :size="12" class="mx-4" v-if="!hasPaymentInitiate" />
              </el-tooltip>
              <el-tooltip
                v-if="!currentTenantBankAccountExist"
                class="item"
                effect="dark"
                :content="$t('payment.paymentTable.tooltips.missingBankAccount')"
                placement="top"
              >
                <QuestionMarkIcon :size="16" class="text-disabled" style="margin-inline-end: 20px" />
              </el-tooltip>
            </div>
            <el-dropdown-item :command="ACTIONS.MARK_AS_PAID">
              <div class="d-flex align-items-center gap-2 text-typography-primary">
                <PaymentDocumentationNISIcon v-if="isNis" />
                <PaymentDocumentationUSDIcon v-else />
                <p>
                  {{
                    $tc('payment.paymentTable.actions.markAsPaid', selectedCount, {
                      count: selectedCount,
                    })
                  }}
                </p>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <ExcelExport :disabled="!sortedImbalances?.length" @on-export-option-choice="exportExcel" />
      </div>
    </div>
    <div>
      <TableLoadingSkeleton v-if="loading" />
      <Table
        v-else
        :columns="columns"
        :data="tableData"
        :row-selection.sync="rowSelection"
        :select-all-toggle="false"
        sticky-columns="2"
        show-index
        border
        rounded
        hover
        custom-class="payment-table"
        :cell-class="handleCellClass"
        @row-click="$emit('row-click', tableData[$event])"
      >
        <template #cell-index="{ rowIndex }">
          {{ rowIndex + 1 + pageLimit * (currentPage - 1) }}
        </template>
        <template
          #cell-paymentDueDate="{ rowData: { paymentDueDate, paymentTerm, paymentStatus, supplier }, rowIndex }"
        >
          <PaymentDueDate
            :payment-due-date="paymentDueDate"
            :payment-term="paymentTerm"
            :payment-status="paymentStatus"
            :show="activeDueDatePopover === rowIndex"
            :action-allowed="true"
            :disabled="updatingTerms || !hasPurchasePaymentTermManage"
            @click="handleDueDateClick(rowIndex)"
            @open-modal="openPaymentDueModal(supplier, paymentTerm)"
          />
        </template>
        <template #cell-paymentTerm="{ rowData: { paymentTerm, supplier } }">
          <PaymentTerm
            :payment-term="paymentTerm"
            :action-allowed="true"
            :disabled="updatingTerms || !hasPurchasePaymentTermManage"
            @open-payment-method-modal="openPaymentMethodModal(supplier, paymentTerm)"
            @open-payment-due-modal="openPaymentDueModal(supplier, paymentTerm)"
          />
        </template>
        <template
          #cell-totalPayment="{
            rowData: {
              totalPayment: { totalBilledAmount, totalPaidAmount },
              unbilledOrders,
              billedAmounts,
              billingIds,
              supplier,
            },
          }"
        >
          <TotalPaymentField
            :billed-amounts="billedAmounts"
            :billing-ids="billingIds"
            :supplier="supplier"
            :customer="customer"
            :total-billed-amount="totalBilledAmount"
            :total-paid-amount="totalPaidAmount"
            :unbilled-orders="unbilledOrders"
            truncated
          />
        </template>
        <template #cell-billedAmounts="{ rowData: { billedAmounts, billingsLeftToBePaid }, rowData }">
          <Button
            v-if="billedAmounts.length"
            type="link"
            class="text-typography-primary p-0"
            @click.stop="paymentInvoicesModalPayment = rowData"
          >
            <template v-if="billingsLeftToBePaid.length !== billedAmounts.length">
              <p>
                {{
                  $t('operationManagement.ongoingOperations.table.billingCountField', {
                    total: billedAmounts.length,
                    leftToPay: billingsLeftToBePaid.length,
                  })
                }}
              </p>
            </template>
            <template v-else>
              <p>{{ billedAmounts.length }}</p>
            </template>
          </Button>
          <p v-else class="text-nowrap">
            <TruncatedText>{{ $t('payment.paymentTable.notYetReceived') }}</TruncatedText>
          </p>
        </template>
        <template
          #cell-reconciliationStatus="{ rowData: { id, reconciliationsStatus, statusOverride, supplier }, rowIndex }"
        >
          <ReconciliationStatus
            :is-admin="isAdmin"
            :reconciliation-id="id"
            :reconciliation-status="reconciliationsStatus"
            :status-override="statusOverride"
            :business-id="businessId"
            :supplier="supplier"
            :show="activeReconciliationStatus === id"
            @open="
              () => {
                activeReconciliationStatus = id;
                actionsVisibleChange(rowIndex, activeReconciliationStatus === id);
              }
            "
            @close="
              () => {
                activeReconciliationStatus = -1;
                actionsVisibleChange(rowIndex, activeReconciliationStatus === id);
              }
            "
          />
        </template>
        <template #cell-approvals="{ rowData: { approvals, id } }">
          <ApproversAvatars
            :approvals="approvals"
            :users="users"
            @create-approval="() => handleCreateApproval(id)"
            @delete-approval="$emit('delete-approval', id)"
          />
        </template>
        <template #cell-actions="{ rowData, rowIndex }">
          <el-dropdown
            class="d-flex justify-content-center"
            trigger="click"
            placement="bottom"
            @command="handleAction($event, rowData)"
            @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
          >
            <Button
              :id="`actions-row-${rowIndex}`"
              type="text"
              class="p-1 actions-btn text-typography-primary"
              :class="{ active: activeRowActionsIndex === rowIndex }"
              @click.stop="handleOuterClick"
            >
              <KebabIcon />
            </Button>
            <el-dropdown-menu>
              <div class="d-flex align-items-center">
                <el-tooltip
                  placement="top"
                  popper-class="custom-tooltip"
                  :content="$t('payment.paymentOperationModal.unbilledOrdersWithoutInvoicesOpened')"
                  :disabled="!!rowData.billingsLeftToBePaid.length"
                  ><span>
                    <el-dropdown-item
                      :disabled="
                        (rowData.unbilledOrders?.length > 0 && !!rowData.billedAmounts) ||
                        !currentTenantBankAccountExist ||
                        !hasPaymentInitiate
                      "
                      :command="ACTIONS.MAKE_PAYMENT"
                    >
                      <div
                        class="d-flex align-items-center gap-2 text-typography-primary"
                        :class="`${
                          (rowData.unbilledOrders?.length > 0 && !!rowData.billedAmounts) ||
                          !currentTenantBankAccountExist ||
                          !hasPaymentInitiate
                            ? 'text-disabled'
                            : 'text-typography-primary'
                        }`"
                      >
                        <PaymentsNisIcon v-if="isNis" />
                        <PaymentsUsdIcon v-else />
                        <p>
                          {{
                            $tc('payment.paymentTable.actions.makePayment', 1, {
                              count: 1,
                              amount: formatToCurrency(
                                rowData.totalPayment.totalBilledAmount - rowData.totalPayment.totalPaidAmount
                              ),
                            })
                          }}
                        </p>
                      </div>
                    </el-dropdown-item></span
                  >
                </el-tooltip>

                <el-tooltip
                  :content="$t('document.documentsOverview.tenantDocumentsTable.downloadFiles.notPermitted')"
                  placement="top"
                >
                  <LockInvertedIcon v-if="!hasPaymentInitiate" :size="12" class="mx-4" />
                </el-tooltip>
                <el-tooltip
                  v-if="!currentTenantBankAccountExist"
                  class="item"
                  effect="dark"
                  :content="$t('payment.paymentTable.tooltips.missingBankAccount')"
                  placement="top"
                >
                  <QuestionMarkIcon :size="16" class="text-disabled" style="margin-inline-end: 20px" />
                </el-tooltip>
              </div>
              <el-dropdown-item
                :disabled="rowData.unbilledOrders.length > 0 && rowData.billedAmounts.length == 0"
                :command="ACTIONS.MARK_AS_PAID"
              >
                <div
                  class="d-flex align-items-center gap-2 text-typography-primary"
                  :class="`${
                    rowData.unbilledOrders.length > 0 && rowData.billedAmounts.length == 0
                      ? 'text-disabled'
                      : 'text-typography-primary'
                  }`"
                >
                  <PaymentDocumentationNISIcon v-if="isNis" />
                  <PaymentDocumentationUSDIcon v-else />
                  <p>
                    {{
                      $tc('payment.paymentTable.actions.markAsPaid', 1, {
                        count: 1,
                      })
                    }}
                  </p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item divided :command="ACTIONS.OPEN_CHAT">
                <div class="d-flex align-items-center gap-2 text-typography-primary">
                  <ChatIcon />
                  <p>{{ $t('payment.paymentTable.singleRowActions.openChat') }}</p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item divided :command="ACTIONS.OPEN_RECONCILIATION_REPORT">
                <div class="d-flex align-items-center gap-2 text-typography-primary">
                  <ReconciliationIcon />
                  <p>{{ $t('payment.paymentTable.singleRowActions.transactions') }}</p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item :command="ACTIONS.OPEN_AGED_DEBTORS_REPORT">
                <div class="d-flex align-items-center gap-2 text-typography-primary">
                  <DebtIcon />
                  <p>{{ $t('payment.paymentTable.singleRowActions.agedDebtorsReport') }}</p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item
                v-if="isAdmin && hasReconciliationManage"
                divided
                :disabled="rowData.totalPayment.totalBilledAmount !== rowData.totalPayment.totalPaidAmount"
                :command="ACTIONS.CLOSE_RECONCILIATION"
              >
                <div
                  class="d-flex align-items-center gap-2 text-typography-primary"
                  :class="`${
                    rowData.totalPayment.totalBilledAmount !== rowData.totalPayment.totalPaidAmount
                      ? 'text-disabled'
                      : 'text-typography-primary'
                  }`"
                >
                  <ChequeredFlagIcon />
                  <p>{{ $t('payment.paymentTable.singleRowActions.closeReconciliation') }}</p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item
                :disabled="rowData.currentStatus === RECONCILIATION_STATUSES.APPROVED || !rowData.currentStatus"
                :command="ACTIONS.OPEN_ISSUES_MODAL"
              >
                <div
                  class="d-flex align-items-center gap-2"
                  :class="`${
                    rowData.currentStatus === RECONCILIATION_STATUSES.APPROVED || !rowData.currentStatus
                      ? 'text-disabled'
                      : 'text-typography-primary'
                  }`"
                >
                  <AccountingIcon />
                  <p>{{ $t('billing.billingManagement.billingTable.actions.openIssuesModal') }}</p>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template
          v-if="!loading"
          #cell-chat="{ rowData: { supplier, id, reconciliationClosed, periodStart, isDaily } }"
        >
          <Button
            v-if="supplier && channelUnreadMessagesCount[id] >= 0"
            class="p-1 hover-btn text-typography-primary d-flex position-relative"
            type="text"
            @click.stop="
              $emit('chat-open', {
                supplier,
                customer,
                reconciliationId: id,
                reconciliationPeriod: periodStart,
                reconciliationClosed,
                isDaily,
              })
            "
          >
            <ChatIcon />
            <span
              v-if="channelUnreadMessagesCount[id] > 0"
              class="badge rounded-pill top-0 bg-danger position-absolute border border-2 border-white"
              :class="{ ['start-0']: $direction === 'rtl', ['end-0']: $direction === 'ltr' }"
            >
              {{ channelUnreadMessagesCount[id] > 99 ? '+99' : channelUnreadMessagesCount[id] }}
            </span>
          </Button>
        </template>
      </Table>
    </div>
    <div class="d-flex justify-content-end my-4 overflow-hidden">
      <el-pagination
        v-if="totalImbalances > pageLimit"
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="currentPage"
        :page-size="pageLimit"
        :page-count="Math.ceil(totalImbalances / pageLimit)"
        :total="totalImbalances"
      />
    </div>
    <PaymentOperationModal
      v-if="paymentOperationModalOpen"
      :payment-imbalances="paymentsToExecute"
      :is-execution="isExecution"
      @close="onPaymentOperationModalClose"
    />
    <PaymentDueModal
      v-if="supplierPaymentDueModal && supplierPaymentDueModal.id"
      :loading="paymentTermLoading || paymentTermChangedForceLoading"
      :supplier="supplierPaymentDueModal.name"
      :country-code="currentTenant.countryCode"
      @close="supplierPaymentDueModal = false"
      @submit="createPaymentDueNetTerm"
    />
    <PaymentMethodModal
      v-if="isPaymentMethodModalOpen"
      :loading="paymentTermLoading || loadingBankAccounts"
      :bank-accounts="bankAccounts"
      @close="isPaymentMethodModalOpen = false"
      @update="handlePaymentMethodChange"
    />
    <IssuesModal
      v-if="issueModalData"
      :month="issueModalData && issueModalData.month"
      :supplier-id="issueModalData && issueModalData.supplierId"
      :business-id="businessId"
      :billing-id="issueModalData && issueModalData.billingId"
      @close="issueModalData = null"
    />
    <PaymentInvoicesModal
      v-if="paymentInvoicesModalPayment"
      :payment="paymentInvoicesModalPayment"
      @close="paymentInvoicesModalPayment = null"
    />
    <ReconciliationModal
      v-if="pickedReconciliationId"
      :reconciliation-id="pickedReconciliationId"
      @reconciliation-closed="$emit('update')"
      @close="$emit('reconciliation-modal-close')"
      @open-chat="$emit('chat-open', $event)"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { uniq, omit, reject, isNil, includes } from 'ramda';
import { ref, computed, watchEffect, watch, onBeforeUnmount, getCurrentInstance } from 'vue';
import { useTenancy } from '@/modules/auth';
import { Table, Button, ExcelExport } from '@/modules/core';
import {
  ChevronIcon,
  PaymentsNisIcon,
  PaymentsUsdIcon,
  ChatIcon,
  KebabIcon,
  ReconciliationIcon,
  ChequeredFlagIcon,
  DebtIcon,
  AccountingIcon,
  CreditCardIcon,
  ChequeIcon,
  BankTransferIcon,
  RecurringIcon,
  QuestionMarkIcon,
  PaymentDocumentationNISIcon,
  PaymentDocumentationUSDIcon,
  LockInvertedIcon,
} from '@/assets/icons';
import { PaymentDueModal, PaymentMethodModal, useCreateTerm, useUpdateTerm } from '@/modules/term';
import { RECONCILIATION_STATUSES, useChannel } from '@/modules/reconciliation';
import { TruncatedText, TableLoadingSkeleton, DropdownTableFilter, DatePickerTableFilter } from '@/modules/core';
import ReconciliationStatus from '@/modules/reconciliation/reconciliationStatus/ReconciliationStatus';
import { RECONCILIATION_STATUS_COLOR } from '@/modules/reconciliation/reconciliationStatus';
import TotalPaymentField from '@/modules/reconciliation/components/TotalPaymentField';
import { options } from '@/locale/dateConfig';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useGlobalPermissions } from '@/modules/permissions';
import { useCurrency as useCurrencyLocale } from '@/locale/useCurrency';
import { exportToExcel } from '@/imports/ui/file_exports';
import { captureScroll } from '@/directives/capture-scroll';

import { useBankAccounts, useUsers } from '../../compositions';
import { PaymentDueDate, PaymentTerm } from '../../components';
import { formatDate, formatBilledDate } from '../../tools/formatters';
import { PAYMENT_STATUS, PAYMENT_METHOD_TYPE } from '../../types';
import ApproversAvatars from './ApproversAvatars';

const TABLE_HEADER = {
  PAYMENT_DUE_DATE: 'paymentDueDate',
  PAYMENT_TERM: 'paymentTerm',
  SUPPLIER_NAME: 'supplierName',
  BILLED_AMOUNTS: 'billedAmounts',
  RECONCILIATION_PERIOD: 'reconciliationPeriod',
  TOTAL_PAYMENT: 'totalPayment',
  PAYMENT_STATUS: 'paymentStatus',
  RECONCILIATION_STATUS: 'reconciliationStatus',
  APPROVALS: 'approvals',
  CHAT: 'chat',
  ACTIONS: 'actions',
};

const ACTIONS = {
  OPEN_CHAT: 'openChat',
  MAKE_PAYMENT: 'makePayment',
  MARK_AS_PAID: 'markAsPaid',
  OPEN_RECONCILIATION_REPORT: 'openReconciliationStatementReport',
  OPEN_AGED_DEBTORS_REPORT: 'openAgedDebtorsReport',
  CLOSE_RECONCILIATION: 'closeReconciliation',
  OPEN_ISSUES_MODAL: 'issuesModal',
};

const PAYMENT_METHODS = {
  BANK_TRANSFER: 'bankTransfer',
  BANK_TRANSFER_DEBIT: 'bankTransferDebit',
  CREDIT_CARD: 'creditCard',
  CREDIT_CARD_DEBIT: 'creditCardDebit',
  CHECK: 'check',
};

const getSorting = ({ column, direction }) => {
  switch (column) {
    case TABLE_HEADER.SUPPLIER_NAME:
      return (a, b) => a.supplier.name.localeCompare(b.supplier.name) * direction;

    default:
      return ({ paymentDueDate: dateA, supplier: supplierA }, { paymentDueDate: dateB, supplier: supplierB }) => {
        // first by due date
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        // second by supplier name
        if (!supplierA || !supplierB) return 1;
        return supplierA.name.localeCompare(supplierB.name);
      };
  }
};

const timeoutIds = [];
const sleep = async (ms) => new Promise((resolve) => timeoutIds.push(setTimeout(resolve, ms)));

export default {
  components: {
    Table,
    TableLoadingSkeleton,
    Button,
    ExcelExport,
    ChevronIcon,
    PaymentsNisIcon,
    PaymentsUsdIcon,
    ChatIcon,
    KebabIcon,
    LockInvertedIcon,
    ReconciliationIcon,
    ChequeredFlagIcon,
    DebtIcon,
    AccountingIcon,
    QuestionMarkIcon,
    DatePickerTableFilter,
    DropdownTableFilter,
    CreditCardIcon,
    ChequeIcon,
    BankTransferIcon,
    RecurringIcon,
    ApproversAvatars,
    ReconciliationStatus,
    TotalPaymentField,
    PaymentDueModal,
    PaymentMethodModal,
    TruncatedText,
    PaymentDocumentationNISIcon,
    PaymentDocumentationUSDIcon,
    PaymentDueDate,
    PaymentTerm,
    PaymentOperationModal: () => import('./PaymentOperationModal'),
    IssuesModal: () => import('@/modules/modals/issuesModal/IssuesModal'),
    PaymentInvoicesModal: () => import('./PaymentInvoicesModal'),
    ReconciliationModal: () => import('@/modules/reconciliation/reconciliationModal/ReconciliationModal'),
  },
  directives: {
    captureScroll,
  },
  props: {
    loading: { type: Boolean, default: false },
    paymentImbalances: { type: Array, default: () => [] },
    customer: { type: Object, required: true },
    isAdmin: { type: Boolean, required: true },
    supplierId: { type: String, default: null },
    toCloseModals: { type: Boolean, default: false },
    pickedReconciliationId: { type: String, default: null },
  },
  setup(props, { emit }) {
    const { $message, $i18n } = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();

    const supplierPaymentDueModal = ref(null);
    const isPaymentMethodModalOpen = ref(false);
    const chosenSupplier = ref(null);
    const chosenSupplierPaymentTerm = ref(null);

    const { bankAccounts, loading: loadingBankAccounts } = useBankAccounts(computed(() => chosenSupplier.value?.id));
    const { bankAccounts: currentTenantBankAccounts } = useBankAccounts(computed(() => currentTenant.value?.id));
    const { formatToCurrency } = useCurrency();
    const { currencyFormat } = useCurrencyLocale();

    const isNis = computed(() => currencyFormat.value.currency === 'ILS');

    const { hasReconciliationManage, hasPaymentInitiate, hasPaymentDocument, hasPurchasePaymentTermManage } =
      useGlobalPermissions();
    const updatingTerms = ref(false);
    const { createTerm, loading: createTermLoading, onError: createTermOnError } = useCreateTerm();

    const termUpdated = () => {
      $message.success($i18n.t('commons.messages.action.success'));
      supplierPaymentDueModal.value = null;
      isPaymentMethodModalOpen.value = false;
      chosenSupplier.value = null;
      chosenSupplierPaymentTerm.value = null;
      emit('update');
      updatingTerms.value = false;
    };

    createTermOnError(() => {
      $message.error($i18n.t('commons.messages.action.error'));
    });

    const { updateTerm, loading: updateTermLoading, onError: updateTermOnError } = useUpdateTerm();

    updateTermOnError(() => {
      $message.error($i18n.t('commons.messages.action.error'));
    });

    const paymentDueDateFilter = ref('');
    const paymentDueDateFilterText = ref('');
    const reconciliationPeriodFilter = ref('');
    const reconciliationPeriodFilterText = ref('');
    const reconciliationStatusFilter = ref('');
    const reconciliationStatusFilterText = ref('');
    const reconciliationPaymentMethodFilterText = ref('');

    const pageLimit = 50;
    const currentPage = ref(1);
    watch(
      [() => paymentDueDateFilter, reconciliationPeriodFilter, reconciliationStatusFilter],
      () => (currentPage.value = 1),
      { immediate: true }
    );
    const paymentMethodFilterList = ref([]);
    const filterByPaymentMethod = (imbalance) => {
      if (!paymentMethodFilterList.value?.length) {
        return imbalance;
      }
      const { paymentTerm } = imbalance;
      if (isNil(paymentTerm)) {
        return paymentTerm;
      }

      const { paymentMethod, directDebit } = paymentTerm;

      switch (paymentMethod) {
        case PAYMENT_METHOD_TYPE.BANK_TRANSFER: {
          if (directDebit) {
            return includes(PAYMENT_METHODS.BANK_TRANSFER_DEBIT, paymentMethodFilterList.value);
          }
          return includes(PAYMENT_METHODS.BANK_TRANSFER, paymentMethodFilterList.value);
        }
        case PAYMENT_METHOD_TYPE.CREDIT_CARD: {
          if (directDebit) {
            return includes(PAYMENT_METHODS.CREDIT_CARD_DEBIT, paymentMethodFilterList.value);
          }
          return includes(PAYMENT_METHODS.CREDIT_CARD, paymentMethodFilterList.value);
        }
        case PAYMENT_METHOD_TYPE.CHEQUE: {
          return includes(PAYMENT_METHODS.CHECK, paymentMethodFilterList.value);
        }
      }
    };

    const filteredImbalances = computed(() => {
      return props.paymentImbalances
        .filter((imbalance) => {
          const filterByDueDate = imbalance.paymentDueDate
            ? DateTime.fromISO(imbalance.paymentDueDate).toFormat('yyyy-LL') === paymentDueDateFilter.value
            : false;

          return paymentDueDateFilter.value ? filterByDueDate : true;
        })
        .filter((imbalance) => {
          if (reconciliationPeriodFilter.value) {
            const billedWithDate = imbalance.billedAmounts.length ? imbalance.billedAmounts : imbalance.unbilledOrders;
            const billedDate = DateTime.fromJSDate(formatBilledDate(billedWithDate, true)).toUTC().toFormat('yyyy-LL');
            return billedDate === reconciliationPeriodFilter.value;
          }
          return true;
        })
        .filter((imbalance) => {
          if (reconciliationStatusFilter.value) {
            if (imbalance.statusOverride) {
              return imbalance.statusOverride.status === reconciliationStatusFilter.value;
            }

            return imbalance.status.status === reconciliationStatusFilter.value;
          }
          return true;
        })
        .filter(filterByPaymentMethod);
    });

    const activeSort = ref({});
    const sortedImbalances = computed(() => [...filteredImbalances.value].sort(getSorting(activeSort.value)));

    const paginatedImbalances = computed(() =>
      sortedImbalances.value.slice((currentPage.value - 1) * pageLimit, currentPage.value * 50)
    );

    const reconciliationIds = computed(() => uniq(paginatedImbalances.value.map(({ id }) => id)));
    const { channelUnreadMessagesCount } = useChannel(reconciliationIds);

    watchEffect(() => emit('view-change', paginatedImbalances.value));

    watch(
      () => props.supplierId,
      () => {
        reconciliationPeriodFilter.value = '';
        paymentDueDateFilter.value = '';
        reconciliationStatusFilter.value = '';
        currentPage.value = 1;
      },
      { immediate: true }
    );

    const paymentOperationModalOpen = ref(false);
    const issueModalData = ref(null);
    const paymentInvoicesModalPayment = ref(null);

    watch(
      () => props.toCloseModals,
      (toCloseModals) => {
        if (toCloseModals) {
          paymentOperationModalOpen.value = false;
          supplierPaymentDueModal.value = null;
          isPaymentMethodModalOpen.value = null;
          issueModalData.value = null;
          paymentInvoicesModalPayment.value = null;
        }
      },
      { immediate: true }
    );

    const userIds = ref([]);

    watch(
      () => props.paymentImbalances,
      (paymentImbalances) => {
        const newUserIds = [
          ...new Set(
            paymentImbalances
              .filter((imbalance) => imbalance?.approvals)
              .flatMap(({ approvals }) => approvals.map(({ approvedBy }) => approvedBy))
          ),
        ];
        const currentUserIds = new Set(userIds.value);
        if (!newUserIds?.every((id) => currentUserIds.has(id))) {
          userIds.value = newUserIds;
        }
      },
      { immediate: true }
    );

    const { users, refetch: refetchUsers } = useUsers(userIds);

    const getISOMonth = (date = DateTime.now()) => date.toUTC().toFormat('yyyy-MM');

    const pickerOptions = {
      shortcuts: [
        {
          text: $i18n.t('bills.currentMonth'),
          onClick: (vm) => {
            vm.$emit('pick', getISOMonth());
          },
        },
        {
          text: $i18n.t('bills.previousMonth'),
          onClick: (vm) => {
            const date = DateTime.now().minus({ months: 1 });
            vm.$emit('pick', getISOMonth(date));
          },
        },
      ],
    };

    onBeforeUnmount(() => timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId)));
    const paymentTermChangedForceLoading = ref(false);
    return {
      hasPaymentInitiate,
      hasPaymentDocument,
      hasReconciliationManage,
      hasPurchasePaymentTermManage,
      formatToCurrency,
      currentTenant,
      currentTenantBankAccounts,
      filterByPaymentMethod,
      paymentMethodFilterList,
      businessId: props.customer.id,
      ACTIONS,
      RECONCILIATION_STATUSES,
      RECONCILIATION_STATUS_COLOR,
      PAYMENT_STATUS,
      PAYMENT_METHODS,
      currentPage,
      pageLimit,
      sortedImbalances,
      paginatedImbalances,
      totalImbalances: computed(() => filteredImbalances.value.length),
      paymentsToExecute: ref([]),
      paymentOperationModalOpen,
      channelUnreadMessagesCount,
      isExecution: ref(false),
      supplierPaymentDueModal,
      createTerm,
      updateTerm,
      activeRowActionsIndex: ref(-1),
      activeDueDatePopover: ref(null),
      activeReconciliationStatus: ref(-1),
      pickerOptions,
      paymentDueDateFilter,
      paymentDueDateFilterText,
      reconciliationPeriodFilter,
      reconciliationPeriodFilterText,
      reconciliationStatusFilter,
      reconciliationStatusFilterText,
      reconciliationPaymentMethodFilterText,
      issueModalData,
      paymentInvoicesModalPayment,
      rowSelectionByPageMap: ref({}),
      PAYMENT_METHOD_TYPE,
      chosenSupplierPaymentTerm,
      isPaymentMethodModalOpen,
      chosenSupplier,
      bankAccounts,
      paymentTermChangedForceLoading,
      paymentTermLoading: computed(() => createTermLoading.value || updateTermLoading.value),
      termUpdated,
      loadingBankAccounts,
      updatingTerms,
      users,
      refetchUsers,
      activeSort,
      isNis,
    };
  },
  computed: {
    paymentImbalancesByTempIds() {
      return this.paymentImbalances.reduce((idsMap, paymentImbalance) => {
        idsMap[paymentImbalance.id] = paymentImbalance;
        return idsMap;
      }, {});
    },
    rowSelection: {
      set(newSelection) {
        const currentPageIds = this.paginatedImbalances.map(({ id }) => id);
        const allSelectedWithoutCurrentPageRows = Object.entries(this.rowSelectionByPageMap).reduce(
          (newMap, [id, imbalance]) => {
            if (!currentPageIds.includes(id)) newMap[id] = imbalance;
            return newMap;
          },
          {}
        );

        const newCurrentPageSelectedImbalances = newSelection.reduce((idsMap, idx) => {
          const imbalanceTempId = currentPageIds[idx];
          const imbalance = this.paymentImbalancesByTempIds[imbalanceTempId];
          idsMap[imbalanceTempId] = imbalance;
          return idsMap;
        }, {});

        this.rowSelectionByPageMap = Object.assign(
          {},
          allSelectedWithoutCurrentPageRows,
          newCurrentPageSelectedImbalances
        );
      },
      get() {
        return this.paginatedImbalances.reduce((selected, { id }, index) => {
          if (this.rowSelectionByPageMap[id]) selected.push(index);
          return selected;
        }, []);
      },
    },
    columns() {
      return [
        {
          header: this.$t('payment.paymentTable.headers.paymentDueDate'),
          label: this.$t('payment.paymentTable.headers.paymentDueDate'),
          key: TABLE_HEADER.PAYMENT_DUE_DATE,
          width: this.$direction === 'ltr' ? '130px' : '90px',
          filterActive: !!this.paymentDueDateFilter,
        },
        {
          header: this.$t('payment.paymentTable.paymentTerms'),
          label: this.$t('payment.paymentTable.paymentTerms'),
          key: TABLE_HEADER.PAYMENT_TERM,
          width: '120px',
        },
        {
          header: this.$t('payment.paymentTable.headers.supplierName'),
          label: this.$t('payment.paymentTable.headers.supplierName'),
          key: TABLE_HEADER.SUPPLIER_NAME,
          width: '180px',
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.SUPPLIER_NAME, direction }),
        },
        {
          header: this.$t('payment.paymentTable.headers.reconciliationPeriod'),
          label: this.$t('payment.paymentTable.headers.reconciliationPeriod'),
          key: TABLE_HEADER.RECONCILIATION_PERIOD,
          width: '130px',
          filterActive: !!this.reconciliationPeriodFilter,
        },
        {
          header: this.$t('payment.paymentTable.headers.totalInvoices'),
          label: this.$t('payment.paymentTable.headers.totalInvoices'),
          key: TABLE_HEADER.BILLED_AMOUNTS,
          width: '90px',
        },
        {
          header: this.$t('payment.paymentTable.headers.totalPayment'),
          label: this.$t('payment.paymentTable.headers.totalPayment'),
          key: TABLE_HEADER.TOTAL_PAYMENT,
          width: '210px',
        },

        {
          header: this.$t('payment.paymentTable.headers.reconciliationStatus'),
          label: this.$t('payment.paymentTable.headers.reconciliationStatus'),
          key: TABLE_HEADER.RECONCILIATION_STATUS,
          width: '110px',
        },
        {
          header: this.$t('payment.paymentTable.headers.approvals'),
          key: TABLE_HEADER.APPROVALS,
          width: '144px',
        },
        {
          header: '',
          key: TABLE_HEADER.CHAT,
          width: '34px',
          customClass: 'p-0',
        },
        {
          header: '',
          key: TABLE_HEADER.ACTIONS,
          width: '40px',
          customClass: 'p-0',
        },
      ];
    },
    tableData() {
      return this.paginatedImbalances.filter(this.filterByPaymentMethod).map((imbalance) => {
        const {
          billingIds,
          paymentDueDate,
          paymentTerm,
          billedAmounts,
          paidAmounts,
          totalBilledAmount,
          totalPaidAmount,
          paymentStatuses,
          overdue,
          supplier,
          billingsLeftToBePaid,
          unbilledOrders,
          id,
          periodStart,
          periodEnd,
          closed,
          approvals,
          status,
          statusOverride,
        } = imbalance;

        const isDaily = periodStart === periodEnd;

        return {
          id,
          [TABLE_HEADER.PAYMENT_DUE_DATE]: paymentDueDate,
          [TABLE_HEADER.SUPPLIER_NAME]: supplier?.name ?? '-',
          [TABLE_HEADER.RECONCILIATION_PERIOD]: this.formatDateShort(periodStart, isDaily),
          [TABLE_HEADER.BILLED_AMOUNTS]: billedAmounts,
          [TABLE_HEADER.TOTAL_PAYMENT]: { totalBilledAmount, totalPaidAmount },
          [TABLE_HEADER.PAYMENT_STATUS]: this.paymentDueNetIsNotDefined(paymentTerm) ? null : paymentStatuses,
          overdue,
          paymentTerm,
          unbilledOrders,
          billingsLeftToBePaid,
          billingIds,
          paidAmounts,
          supplier,
          customer: this.currentTenant,
          reconciliationsStatus: status,
          statusOverride,
          reconciliationClosed: closed,
          periodStart,
          isDaily,
          approvals,
          currentStatus: statusOverride?.status ?? status?.status,
          selectionDisabled: !this.hasPaymentInitiate && !this.hasPaymentDocument,
        };
      });
    },
    selectedAmount() {
      return Object.values(this.rowSelectionByPageMap).reduce(
        (total, { totalBilledAmount, totalPaidAmount }) => total + totalBilledAmount - totalPaidAmount,
        0
      );
    },
    selectedCount() {
      return Object.keys(this.rowSelectionByPageMap).length;
    },
    currentTenantBankAccountExist() {
      return !!this.currentTenantBankAccounts?.length;
    },
  },
  watch: {
    paymentImbalances() {
      this.rowSelection = [];
      this.rowSelectionByPageMap = {};
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOuterClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOuterClick);
  },
  methods: {
    onScroll() {
      if (this.activeRowActionsIndex !== -1) {
        document.getElementById(`actions-row-${this.activeRowActionsIndex}`).click();
        this.activeRowActionsIndex = -1;
      }
      this.activeDueDatePopover = null;

      this.activeReconciliationStatus = -1;
    },
    paymentDueNetIsNotDefined(paymentTerm) {
      return !paymentTerm || (paymentTerm && !Number.isInteger(paymentTerm.paymentDueNet));
    },
    formatDateShort(date, isDaily) {
      if (!date) return '';
      if (isDaily) return new Date(date).toLocaleDateString(this.$i18n.locale, { ...options.twoDigits });
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        month: 'short',
        year: '2-digit',
        timeZone: 'UTC',
      });
    },
    onPaymentOperationModalClose() {
      this.isExecution = false;
      this.paymentOperationModalOpen = false;
      this.rowSelection = [];
      this.rowSelectionByPageMap = {};
      this.paymentsToExecute = [];
    },
    openPaymentOperationModal(command) {
      if (command === ACTIONS.MAKE_PAYMENT) this.isExecution = true;
      this.paymentsToExecute = Object.values(this.rowSelectionByPageMap);
      this.paymentOperationModalOpen = true;
    },
    openPaymentMethodModal(supplier, paymentTerm) {
      this.chosenSupplier = supplier;
      this.chosenSupplierPaymentTerm = paymentTerm;
      this.isPaymentMethodModalOpen = true;
    },
    openPaymentDueModal(supplier, paymentTerm) {
      this.supplierPaymentDueModal = supplier;
      this.chosenSupplierPaymentTerm = paymentTerm;
    },
    actionsVisibleChange(index, isVisible) {
      this.activeRowActionsIndex = isVisible ? index : -1;
    },
    async handleAction(command, rowData) {
      const { supplier, billedAmounts, id: tempId, billingId, reconciliationClosed, periodStart, isDaily } = rowData;
      switch (command) {
        case ACTIONS.OPEN_CHAT:
          this.$emit('chat-open', {
            supplier,
            customer: this.customer,
            reconciliationId: tempId,
            reconciliationPeriod: periodStart,
            reconciliationClosed,
            isDaily,
          });
          break;
        case ACTIONS.MAKE_PAYMENT:
          this.isExecution = true;
        // eslint-disable-next-line no-fallthrough
        case ACTIONS.MARK_AS_PAID:
          this.paymentsToExecute = [this.paymentImbalancesByTempIds[tempId]];
          this.paymentOperationModalOpen = true;
          break;
        case ACTIONS.OPEN_RECONCILIATION_REPORT:
          this.routeToReconciliationStatementReport(supplier.id);
          break;
        case ACTIONS.OPEN_AGED_DEBTORS_REPORT:
          this.routeToAgedDebtorsReport(supplier.id);
          break;
        case ACTIONS.CLOSE_RECONCILIATION:
          {
            try {
              await this.$confirm(
                this.$t('payment.paymentTable.reconciliationCloseConfirm.text', {
                  date: rowData.reconciliationPeriod,
                  client: rowData.supplierName,
                }),
                this.$t('payment.paymentTable.reconciliationCloseConfirm.title'),
                {
                  showClose: false,
                  confirmButtonText: this.$t('payment.paymentTable.reconciliationCloseConfirm.confirm'),
                  cancelButtonText: this.$t('payment.paymentTable.reconciliationCloseConfirm.cancel'),
                  confirmButtonClass: 'el-button--danger',
                  cancelButtonClass: 'el-button--secondary',
                }
              );
              this.$emit('close-reconciliation', tempId);
              // eslint-disable-next-line no-empty
            } catch (err) {}
          }

          break;
        case ACTIONS.OPEN_ISSUES_MODAL:
          this.issueModalData = {
            supplierId: supplier.id,
            month: formatBilledDate(billedAmounts, true).toISOString().slice(0, 7),
            billingId,
          };
          break;
        default:
          break;
      }
    },

    formatDate,
    async createPaymentDueNetTerm(newPaymentDueNet, eom) {
      this.updatingTerms = true;
      this.paymentTermChangedForceLoading = true;
      if (!this.chosenSupplierPaymentTerm) {
        await this.createTerm({
          termCreateInput: {
            businessId: this.customer.id,
            supplierId: this.supplierPaymentDueModal.id,
            fromDate: new Date(),
            type: 'payment',
            paymentDueNet: newPaymentDueNet,
            eom,
          },
        });
      } else {
        await this.updateTerm({
          termId: this.chosenSupplierPaymentTerm.id,
          termUpdateInput: reject(isNil, {
            businessId: this.customer.id,
            ...omit(['id', 'active', '__typename'], this.chosenSupplierPaymentTerm),
            paymentDueNet: newPaymentDueNet,
            eom,
          }),
        });
      }
      await sleep(8000);
      this.paymentTermChangedForceLoading = false;
      this.termUpdated();
    },
    async handlePaymentMethodChange(newPaymentMethod) {
      this.updatingTerms = true;
      if (!this.chosenSupplierPaymentTerm) {
        await this.createTerm({
          termCreateInput: reject(isNil, {
            businessId: this.customer.id,
            supplierId: this.chosenSupplier.id,
            fromDate: new Date(),
            type: 'payment',
            ...newPaymentMethod,
          }),
        });
      } else {
        await this.updateTerm({
          termId: this.chosenSupplierPaymentTerm.id,
          termUpdateInput: reject(isNil, {
            businessId: this.customer.id,
            ...omit(['id', 'active', '__typename'], this.chosenSupplierPaymentTerm),
            ...newPaymentMethod,
          }),
        });
      }
      this.termUpdated();
    },
    routeToReconciliationStatementReport(supplierId) {
      const route = this.$router.resolve({
        name: 'reconciliationStatementReport',
        params: { supplierId },
      });
      window.open(route.href, '_blank');
    },
    routeToAgedDebtorsReport(supplierId) {
      const route = this.$router.resolve({
        name: 'agedDebtorsReport',
        params: { supplierId },
      });
      window.open(route.href, '_blank');
    },
    handleCellClass(rowIndex) {
      if (
        this.activeRowActionsIndex === rowIndex ||
        this.activeDueDatePopover === rowIndex ||
        this.activeReconciliationStatus === rowIndex
      )
        return 'bg-secondary';
    },
    handleDueDateClick(rowIndex) {
      this.activeDueDatePopover = this.activeDueDatePopover === rowIndex ? null : rowIndex;
    },
    handleOuterClick() {
      this.activeDueDatePopover = null;
    },
    onPaymentDueDateFilterChanged(date) {
      this.paymentDueDateFilter = date ?? null;
      this.paymentDueDateFilterText = date ? DateTime.fromISO(date).toFormat('MMM yy') : null;
      this.activeReconciliationStatus = -1;
    },
    onBillingDateFilterChanged(date) {
      this.reconciliationPeriodFilter = date ?? null;
      this.reconciliationPeriodFilterText = date ? DateTime.fromISO(date).toFormat('MMM yy') : null;
      this.activeReconciliationStatus = -1;
    },
    onReconciliationStatusFilterChanged(command) {
      this.reconciliationStatusFilter = command ?? null;
      this.reconciliationStatusFilterText = command ? this.$i18n.t(`reconciliation.exports.status.${command}`) : null;
      this.activeReconciliationStatus = -1;
    },
    onReconciliationPaymentMethodFilterChanged() {
      if (!this.paymentMethodFilterList?.length) {
        this.reconciliationPaymentMethodFilterText = '';
      } else if (this.paymentMethodFilterList?.length === 1) {
        const [filterTitle] = this.paymentMethodFilterList;
        this.reconciliationPaymentMethodFilterText = this.$t(
          `payment.paymentTable.filtersPaymentMethod.${filterTitle}`
        );
      } else if (this.paymentMethodFilterList?.length > 1) {
        this.reconciliationPaymentMethodFilterText = `(${this.paymentMethodFilterList?.length})`;
      }
      this.currentPage = 1;
    },
    onReconciliationPaymentMethodFilterReset() {
      this.reconciliationPaymentMethodFilterText = null;
      this.paymentMethodFilterList = [];
      this.activeReconciliationStatus = -1;
    },
    handleCreateApproval(id) {
      this.refetchUsers();
      this.$emit('create-approval', id);
    },
    exportExcel() {
      const { PAYMENT_STATUS, APPROVALS, CHAT, ACTIONS } = TABLE_HEADER;
      const columns = this.columns.filter(({ key }) => ![PAYMENT_STATUS, APPROVALS, CHAT, ACTIONS].includes(key));
      const metadata = {
        filename: this.$t('routes.payments'),
        sheetname: this.$t('payment.paymentTable.paymentsForApproval'),
        direction: this.$t('direction'),
        columns,
      };

      const data = this.sortedImbalances.map(
        ({
          paymentDueDate,
          supplier,
          periodStart,
          periodEnd,
          status,
          statusOverride,
          billingsLeftToBePaid,
          billedAmounts,
          totalBilledAmount,
          totalPaidAmount,
          paymentTerm,
        }) => {
          const paymentTerms = () => {
            if (!paymentTerm) return this.$t('payment.paymentTable.notDefined');
            const paymentTerms = [];
            if (paymentTerm.paymentMethod) {
              const paymentMethod = this.$t(`payment.exports.paymentMethods.fullName.${paymentTerm.paymentMethod}`);
              if (paymentTerm.directDebit) {
                const directDebit = this.$t('payment.paymentTable.directDebit');
                paymentTerms.push(`${paymentMethod} - ${directDebit}`);
              } else paymentTerms.push(paymentMethod);
            }
            if (!this.paymentDueNetIsNotDefined(paymentTerm))
              paymentTerms.push(
                this.$tc(
                  `payment.paymentTable.paymentTermsPopover.paymentDueNet${paymentTerm.eom ? 'Eom' : ''}`,
                  paymentTerm.paymentDueNet,
                  {
                    count: paymentTerm.paymentDueNet,
                  }
                )
              );
            return paymentTerms.join(', ');
          };
          const invoicesToPay = () => {
            if (billedAmounts.length) {
              return billingsLeftToBePaid.length !== billedAmounts.length
                ? this.$t('operationManagement.ongoingOperations.table.billingCountField', {
                    total: billedAmounts.length,
                    leftToPay: billingsLeftToBePaid.length,
                  })
                : billedAmounts.length;
            } else return this.$t('payment.paymentTable.notYetReceived');
          };
          const calculatedStatus = statusOverride?.status ?? status?.status;

          return {
            [TABLE_HEADER.PAYMENT_DUE_DATE]: this.formatDate(paymentDueDate),
            [TABLE_HEADER.PAYMENT_TERM]: paymentTerms(),
            [TABLE_HEADER.SUPPLIER_NAME]: supplier.name,
            [TABLE_HEADER.RECONCILIATION_PERIOD]: this.formatDateShort(periodStart, periodStart === periodEnd),
            [TABLE_HEADER.BILLED_AMOUNTS]: invoicesToPay(),
            [TABLE_HEADER.TOTAL_PAYMENT]: totalBilledAmount - totalPaidAmount,
            [TABLE_HEADER.RECONCILIATION_STATUS]: calculatedStatus
              ? this.$t(`billing.exports.status.${calculatedStatus}`)
              : '-',
          };
        }
      );

      exportToExcel(metadata, data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.warning-button {
  padding-top: 2px;
}

button.text-warning.warning:not(:hover) {
  color: change-color($color: $warning, $lightness: 38.1%) !important;
}
button.text-danger.danger:not(:hover) {
  color: change-color($color: $error, $lightness: 40%) !important;
}

.payment-table {
  .actions-btn,
  .add-btn {
    &:hover {
      background: $secondary;
    }
    &.active {
      visibility: visible;
    }
  }

  .hover-btn {
    &:hover {
      background: $secondary;
    }
  }

  tr {
    .actions-btn {
      visibility: hidden;
    }

    &:hover .actions-btn {
      visibility: visible;
    }
  }
}

.badge-position {
  top: 25%;
  [dir='rtl'] & {
    left: 100%;
    transform: translate(-50%, -50%);
  }
  [dir='ltr'] & {
    left: 0;
    transform: translate(-50%, -50%) scaleX(-1);
  }
}
.table-filters {
  display: flex;
  gap: 0.5rem;
}

.reconciliation-status-filter {
  display: flex;
  gap: 0.25rem;
  align-items: center;

  .reconciliation-status-filter-icon {
    height: 8px;
    width: 8px;
    padding: 0.25rem;
    border-radius: 50%;
  }
}

.reconciliation-payment-method-dropdown {
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;

    .el-checkbox {
      margin: 0;
      padding: 5px 0px;
    }
  }

  .reconciliation-payment-method-filter {
    display: flex;
    align-items: center;
    position: relative;
    font-weight: normal;
    color: rgb(31, 40, 77);

    .payment-method-title {
      margin: 0px 5px;
    }

    .reconciliation-payment-method-filter-icon {
      .method-icon {
        width: 16px;
        height: 16px;
      }
      .recurring-icon {
        position: absolute;
        width: 12px;
        height: 12px;
        top: -2px;

        [dir='rtl'] & {
          right: -5px;
        }
        [dir='ltr'] & {
          left: -5px;
        }
      }
    }
  }
}

.payments-table-title {
  height: 2rem;
}

.top-section {
  top: $page-layout-dashboard-hight;
  padding: 1rem 0;
}
</style>
